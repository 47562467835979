import { Fragment, useState, useContext, useCallback, useMemo, useRef } from 'react';
import CashField from '../../../Common/CashField/CashField';
import MoneyOrderCart from './MoneyOrderCart/MoneyOrderCart';
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import AdvancedButton from '../../../Common/AdvancedButton/AdvancedButton';
import packageJson from '../../../../../package.json';
import Message from '../../../Common/Message/Message';
import './MoneyOrder.css';
import { SystemContext } from '../../../HigherOrder/SystemConroller/SystemController';
import { useHistory } from 'react-router-dom';
import { MOContext } from '../../../HigherOrder/MoneyOrderController/MoneyOrderController';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { AuthContext } from '../../../HigherOrder/AuthController/AuthController';
import Processing from '../../../Common/Processing/Processing';
import ErrorPopup from './ErrorPopup/ErrorPopup';
import type { FormikContextType, FormikHelpers, FormikProps } from 'formik';
import { softwareName } from 'utilities/Environment';
import { tooltipBoldClass } from 'utilities/tooltip';
import { js2xml, xml2js } from 'utilities/xml';
import { serverFormat } from 'components/hooks/TimeZone';
import InstrumentLog from './AdditionalInfoPopups/InstrumentLog';

export interface MOResponseBody {
    routeid: string,
    agent: string,
    mpf: string,
    trancode: string,
    response_code: string,
    response_msg1: string,
    response_msg2: string,
    vcenter_dttm: string,
    parm_dttm: string,
    trandate: string,
    trantime: string,
    busdate: string,
    tracer: string,
    ovrmfee: string,
    rttran1: string,
    bankac1: string,
    checkno1: string,
    checkamt1: string,
    tracer1: string
    rttran2: string,
    bankac2: string,
    checkno2: string,
    checkamt2: string,
    tracer2: string,
    rttran3: string,
    bankac3: string,
    checkno3: string,
    checkamt3: string,
    tracer3: string,
    rttran4: string,
    bankac4: string,
    checkno4: string,
    checkamt4: string,
    tracer4: string,
    rttran5: string,
    bankac5: string,
    checkno5: string,
    checkamt5: string,
    tracer5: string,
    rttran6: string,
    bankac6: string,
    checkno6: string,
    checkamt6: string,
    tracer6: string,
    rttran7: string,
    bankac7: string,
    checkno7: string,
    checkamt7: string,
    tracer7: string,
    rttran8: string,
    bankac8: string,
    checkno8: string,
    checkamt8: string,
    tracer8: string,
    rttran9: string,
    bankac9: string,
    checkno9: string,
    checkamt9: string,
    tracer9: string,
    rttran10: string,
    bankac10: string,
    checkno10: string,
    checkamt10: string,
    tracer10: string,
    rttran11: string,
    bankac11: string,
    checkno11: string,
    checkamt11: string,
    tracer11: string,
    rttran12: string,
    bankac12: string,
    checkno12: string,
    checkamt12: string,
    tracer12: string,
    rttran13: string,
    bankac13: string,
    checkno13: string,
    checkamt13: string,
    tracer13: string,
    rttran14: string,
    bankac14: string,
    checkno14: string,
    checkamt14: string,
    tracer14: string,
    rttran15: string,
    bankac15: string,
    checkno15: string,
    checkamt15: string,
    tracer15: string,
    rttran16: string,
    bankac16: string,
    checkno16: string,
    checkamt16: string,
    tracer16: string,
    rttran17: string,
    bankac17: string,
    checkno17: string,
    checkamt17: string,
    tracer17: string,
    rttran18: string,
    bankac18: string,
    checkno18: string,
    checkamt18: string,
    tracer18: string,
    rttran19: string,
    bankac19: string,
    checkno19: string,
    checkamt19: string,
    tracer19: string,
    rttran20: string,
    bankac20: string,
    checkno20: string,
    checkamt20: string,
    tracer20: string
}

export interface MOResponse {
    moneyorder_response: MOResponseBody
}

export interface MoneyOrderItem {
    amount: number,
    fee: number,
    serialNumber: string
}

const initialAdditionalFields = {
    clerkName: "",

    name: "",
    phone: "",
    addr1: "",
    city: "",
    state: "",
    zip: "",

    idType: "",
    idIssuer: "",
    idNumber: "",

    birthdate: {
        day: '',
        month: '',
        year: ''
    },

    ssnTin: ""
}

export const initialValues = {
    cash: '0',
    ...initialAdditionalFields
};

const tutorialAmount = '9900';

/**
 * Page for printing money orders.
 * Heavily dependent on the MoneyOrderController component, which should be located somewhere above this component.
 * Settings are set in the MoneyOrderSettings component.
 * Money orders are sent to the server, then printed in the printer.
 */
export default function MoneyOrder() {

    const [moneyOrders, setMoneyOrders] = useState<MoneyOrderItem[]>([]);
    const [selectedMoneyOrder, setSelectedMoneyOrder] = useState<null | MoneyOrderItem>(null);
    const [errorResponse, setErrorResponse] = useState<MOResponseBody | null>(null);
    const [errorIndex, setErrorIndex] = useState(-1);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const actionLog = useRef<string[]>([]);
    const [popupOpen, setPopupOpen] = useState(true);

    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { setTrainingSetting } = useContext(SettingsContext);
    const { push } = useHistory();
    const { resetStatus, incrementMOSerialNumber, printMoneyOrder } = useContext(MOContext);
    const { failoverFetch } = useContext(APIContext);
    const { auth } = useContext(AuthContext);
    const hasAuth = !!auth;
    const { parms } = useContext(ParmContext);
    const hasParms = !!parms;
    const { system: { serial } } = useContext(SystemContext);

    const logAction = useCallback((action: string) => {
        actionLog.current.push(action);
        console.log(action);
    }, [])

    const clearCart = ({ setStatus, resetForm }: FormikHelpers<typeof initialValues>) => {
        setStatus({ message: 'Money Order Successful', messageType: '' });
        setSelectedMoneyOrder(null);
        setMoneyOrders([]);
        resetForm();
        setPopupOpen(true);
    }

    const sendActionLog = useCallback(async () => {
        if (auth && errorResponse && actionLog.current.length > 0) {
            try {
                await failoverFetch('/saveMo', {
                    method: 'POST',
                    body: new URLSearchParams({
                        authToken: auth.authToken,
                        moNum: Number(errorResponse['checkno1'].slice(0, -1)).toString(),
                        actions: JSON.stringify(actionLog.current),
                    })
                });
            } catch (error) {
                console.error(error);
            } finally {
                actionLog.current = [];
            }
        }
    }, [auth, errorResponse, failoverFetch])

    const clearErrors = useCallback(() => {
        sendActionLog();
        setErrorResponse(null);
        setErrorIndex(-1);
        setErrorMessage(null);
    }, [sendActionLog])

    const saveMoneyOrder = useCallback(async (tracNum: number) => {
        if (hasAuth && hasParms) {
            try {
                await failoverFetch('/saveMo?' + new URLSearchParams({
                    tracNum: tracNum.toString(),
                    clerkId: parms.clerkInfo.clerkId.toString(),
                    authToken: auth.authToken,
                    receipt: "Money Order"
                }))
            } catch (error) {
                console.error(error);
            }
        }
    }, [auth, failoverFetch, hasAuth, hasParms, parms?.clerkInfo.clerkId])

    const handlePrintMoneyOrder = useCallback(async (paymentResponse: MOResponseBody, index: number) => {
        if (paymentResponse['checkamt' + index as keyof MOResponseBody]) {
            const amount = Math.round(Number(paymentResponse['checkamt' + index as keyof MOResponseBody]) * 100);
            try {
                console.log(`Sending to printer: ${Number(paymentResponse['checkno' + index as keyof MOResponseBody])}`)
                await printMoneyOrder(
                    amount,
                    Number(paymentResponse['checkno' + index as keyof MOResponseBody]),
                    paymentResponse['tracer' + index as keyof MOResponseBody],
                    Number(paymentResponse.agent),
                    paymentResponse.trandate,
                    paymentResponse.trantime,
                    false);
            } catch (error: any) {
                console.error(error.toString());
                setErrorMessage(error?.toString());
                setErrorResponse(paymentResponse);
                setErrorIndex(index);
                if (error.toString().includes('MOs out of Seq')) {
                    try {
                        await printMoneyOrder(
                            amount,
                            Number(paymentResponse['checkno' + index as keyof MOResponseBody]),
                            paymentResponse['tracer' + index as keyof MOResponseBody],
                            Number(paymentResponse.agent),
                            paymentResponse.trandate,
                            paymentResponse.trantime,
                            true);
                    } catch (error2: any) {
                        setErrorMessage(error2?.toString());
                    }
                }
            }
        }
    }, [printMoneyOrder])

    const printMoneyOrders = useCallback(async (paymentResponse: MOResponseBody, actions: FormikHelpers<typeof initialValues>, startIndex = 1, retry = false) => {
        actions.setStatus({ message: 'Printing Money Orders...', messageType: '' });
        for (let i = startIndex; paymentResponse['rttran' + i as keyof MOResponseBody]; i++) {
            if (!retry) {
                saveMoneyOrder(Number(paymentResponse['tracer' + i as keyof MOResponseBody]));
            } else {
                retry = false;
            }
            await handlePrintMoneyOrder(paymentResponse, i);
        }
        clearCart(actions);
    }, [handlePrintMoneyOrder, saveMoneyOrder])

    const needsInstrumentLog = useMemo(() =>
        moneyOrders.reduce((sum, { amount }) => sum + amount, 0) >= 300000
        , [moneyOrders]);

    const sendMoneyOrderRequest = useCallback(async (values: typeof initialValues) => {
        if (hasParms && hasAuth) {
            const xml = 'bigField=' + js2xml({
                moneyorder: {
                    mo_req_header: {
                        routeid: parms.parameters.moRoute,
                        agent: parms.clerkInfo.agentId.toString().padStart(7, '0'),
                        devserno: serial,
                        serno: '',
                        softname: softwareName,
                        softrel: packageJson.version,
                        seqno: '0',
                        trandate: serverFormat(new Date(), 'yyyyMMdd'),
                        trantime: serverFormat(new Date(), 'HHmmss'),
                        voidflag: '',
                        trancode: '91',
                        upflag: '',
                        clerkid: parms.clerkInfo.clerkId.toString(),
                        movtrac: '0',
                        authToken: auth.authToken
                    },
                    clerkInfo: {
                        clerkName: values.clerkName
                    },
                    consumerInfo: {
                        name: values.name,
                        phone: values.phone,
                        addr1: values.addr1,
                        city: values.city,
                        state: values.state,
                        zip: values.zip,

                        idType: values.idType,
                        idIssuer: values.idIssuer,
                        idNumber: values.idNumber,

                        birthdate: `${values.birthdate.year}${values.birthdate.month.padStart(2, '0')}${values.birthdate.day.padStart(2, '0')}`,

                        ssnTin: values.ssnTin
                    },
                    mo_req_detail: moneyOrders.map(({ serialNumber, amount, fee }) => ({
                        mo_req: {
                            moserno: serialNumber,
                            moamt: (amount / 100).toFixed(2),
                            feeamt: (fee / 100).toFixed(2)
                        }
                    }))
                }
            });

            const response = await failoverFetch('/mogateway', {
                method: 'POST',
                body: xml,
                headers: { 'Content-Type': 'text/xml' }
            });
            const data = response.replace(/#/g, '').trim();
            if (data.length === 0) {
                return Promise.reject();
            } else {
                return xml2js<MOResponse>(data).moneyorder_response;
            }
        }
    }, [auth, failoverFetch, hasAuth, hasParms, moneyOrders, parms?.clerkInfo.agentId, parms?.clerkInfo.clerkId, parms?.parameters.moRoute, serial])

    const onSubmit = useCallback(async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        if (isCurrentPageTraining) {
            setTrainingSetting({ mo: true });
            push('/MoneyOrder');
            actions.resetForm();
            setSelectedMoneyOrder(null);
            setMoneyOrders([]);
        } else {
            actions.setStatus({ message: 'Validating Money Orders...', messageType: '' });
            clearErrors();
            try {
                await resetStatus();
                const parsedResponse = await sendMoneyOrderRequest(values);
                if (parsedResponse && parsedResponse.response_code !== '00') {
                    actions.setStatus({ message: parsedResponse.response_msg1 + ' ' + parsedResponse.response_msg2, messageType: 'error' });
                } else if (parsedResponse) {
                    incrementMOSerialNumber(moneyOrders.length);
                    try {
                        await printMoneyOrders(parsedResponse, actions, undefined, undefined);
                    } catch (error) {
                        console.error(error);
                    }
                }
            } catch (error) {
                console.error(error);
                actions.setStatus({ message: 'An unexpected error has occurred. Please make sure your printer is ready to print.', messageType: 'error' });
            } finally {
                actions.setSubmitting(false);
            }
        }
    }, [clearErrors, incrementMOSerialNumber, isCurrentPageTraining, moneyOrders.length, printMoneyOrders, push, resetStatus, sendMoneyOrderRequest, setTrainingSetting])

    const handleRetry = useCallback(async (actions: FormikContextType<typeof initialValues>) => {
        const errorResponseCopy = { ...errorResponse } as MOResponseBody;
        const errorIndexCopy = errorIndex;
        clearErrors();
        if (errorResponseCopy) {
            actions.setSubmitting(true);
            await printMoneyOrders(errorResponseCopy, actions, errorIndexCopy, true);
            actions.setSubmitting(false);
        }
    }, [clearErrors, errorIndex, errorResponse, printMoneyOrders])

    const handleContinue = useCallback(async (actions: FormikContextType<typeof initialValues>) => {
        const errorResponseCopy = { ...errorResponse } as MOResponseBody;
        const errorIndexCopy = errorIndex;
        clearErrors();
        if (errorResponseCopy) {
            actions.setSubmitting(true);
            await printMoneyOrders(errorResponseCopy, actions, errorIndexCopy + 1);
            actions.setSubmitting(false);
        }
    }, [clearErrors, errorIndex, errorResponse, printMoneyOrders])

    const outOfSequence = !!(errorMessage?.includes('MOs out of Seq'));

    const shouldShowErrorPopup = !!errorResponse && errorIndex > -1 && !!errorMessage;

    const handlePopupOpen = () => {
        setPopupOpen(true);
    }

    const handlePopupClose = () => {
        setPopupOpen(false);
    }

    return useMemo(() =>
        <AdvancedForm className='MoneyOrder' onSubmit={onSubmit} initialValues={initialValues} >
            {({ values, isSubmitting }: FormikProps<typeof initialValues>) => {

                const isMOAmountTutorial = Number(values.cash) !== Number(tutorialAmount) && moneyOrders.length === 0;
                const isAddButtonTutorial = !isMOAmountTutorial && moneyOrders.length === 0;
                const isPrintButtonTutorial = !isMOAmountTutorial && !isAddButtonTutorial;

                return (
                    <Fragment>
                        <InstrumentLog
                            onClose={handlePopupClose}
                            open={needsInstrumentLog && popupOpen}
                            setMoneyOrders={setMoneyOrders} />
                        <ErrorPopup
                            onContinue={handleContinue}
                            onRetry={handleRetry}
                            paymentResponse={errorResponse}
                            index={errorIndex}
                            message={errorMessage}
                            outOfSequence={outOfSequence}
                            clearErrors={clearErrors}
                            open={!!shouldShowErrorPopup}
                            logAction={logAction} />
                        <fieldset className='MoneyOrderAmount'>
                            <legend>
                                MONEY ORDER AMOUNT
                            </legend>
                            <CashField name='cash'
                                disabled={!!selectedMoneyOrder || !!errorResponse}
                                autoFocus={true}
                                trainingFocused={isMOAmountTutorial}
                                trainingTooltip={
                                    <>
                                        <div>ENTER THIS AMOUNT</div>
                                        <b className={tooltipBoldClass}>{'$' + (Number(tutorialAmount) / 100).toFixed(2)}</b>
                                    </>
                                }
                                placement='bottom' />
                        </fieldset>
                        <fieldset>
                            <MoneyOrderCart moneyOrders={moneyOrders}
                                selectedMoneyOrder={selectedMoneyOrder}
                                setMoneyOrders={setMoneyOrders}
                                setSelectedMoneyOrder={setSelectedMoneyOrder}
                                isAddButtonTutorial={isAddButtonTutorial}
                                hasError={!!errorResponse} />
                            {needsInstrumentLog &&
                                <AdvancedButton 
                                    type='button'
                                    className='CustomerInformationButton'
                                    onClick={handlePopupOpen}>Customer Information</AdvancedButton>
                            }
                            <AdvancedButton
                                disabled={moneyOrders.length === 0 || !!errorResponse}
                                className ='Print'
                                trainingFocused={isPrintButtonTutorial}
                                trainingTooltip={
                                    <>
                                        <div>YOU ARE READY</div>
                                        <b className={tooltipBoldClass}>CLICK PRINT</b>
                                    </>
                                }
                                tooltipPlacement='left' >Print</AdvancedButton>
                        </fieldset>
                        <Processing open={isSubmitting} />
                        <Message />
                    </Fragment>
                );
            }
            }
        </AdvancedForm>
        , [clearErrors, errorIndex, errorMessage, errorResponse, handleContinue, handleRetry, logAction, moneyOrders, needsInstrumentLog, onSubmit, outOfSequence, popupOpen, selectedMoneyOrder, shouldShowErrorPopup]);
}