import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Field, useFormikContext } from 'formik';
import BasicPopup from '../../../../Common/Popups/BasicPopup/BasicPopup';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import MessageAggregator from '../../../../Common/MessageAggregator/MessageAggregator';
import { validateAddress, validateCity, validateName, validateState, validateZip, validatePhone } from '../../../../../utilities/Validation';
import { differenceInYears } from 'date-fns';
import { stateOptions, focusNextElement } from '../../../../../utilities/Tools';
import './InstrumentLog.css';
import type { initialValues, MoneyOrderItem } from '../MoneyOrder';

const noNameCopyPaste = (name: string, { city, addr1, phone }: typeof initialValues) => {
    return !((name.toLowerCase().trim() === city.toLowerCase().trim() && city)
        || (name.toLowerCase().trim() === addr1.toLowerCase().trim() && addr1)
        || (name.toLowerCase().trim() === phone.toLowerCase().trim() && phone));
}

interface Props {
    onClose: () => void,
    open: boolean,
    setMoneyOrders: Dispatch<SetStateAction<MoneyOrderItem[]>>,
}

export default function InstrumentLog({ onClose, open, setMoneyOrders }: Props) {

    const { values, touched, setTouched } = useFormikContext<typeof initialValues>();

    const handleValidateName = (name: string) => {
        const value = name.trim();
        if (!value) {
            return 'Name Required';
        } else if (!noNameCopyPaste(name, values)) {
            return 'Name must not match other fields'
        }
        return validateName(name);
    }

    const handleValidatePhone = (phone = values.phone) => {
        const value = phone.trim();
        if (!value) {
            return 'Phone Required';
        }
        return validatePhone(phone);
    }

    const handleValidateAddress = (address = values.addr1) => {
        const value = address.trim();
        if (!value) {
            return 'Address Required';
        }
        return validateAddress(address);
    }

    const handleValidateCity = (city = values.city) => {
        const value = city.trim();
        if (!value) {
            return 'City Required';
        }
        return validateCity(city);
    }

    const handleValidateState = (state = values.state) => {
        const value = state.trim();
        if (!value) {
            return 'State Required';
        }
        return validateState(state);
    }

    const handleValidateZip = (zip = values.zip) => {
        const value = zip.trim();
        if (!value) {
            return 'Zip Required';
        }
        return validateZip(zip);
    }

    const handleValidateIdType = (idType = values.idType) => {
        const value = idType.trim();
        if (!value) {
            return 'ID Type Required';
        }
    }

    const handleValidateIdIssuer = (idIssuer = values.idIssuer) => {
        const value = idIssuer.trim();
        if (!value) {
            return 'ID Issuer Required';
        }
    }
    const handleValidateIdNumber = (idNumber = values.idNumber) => {
        const value = idNumber.trim();
        if (!value) {
            return 'ID Number Required';
        }
    }

    const handleValidateBirthdate = () => {
        const { birthdate: { day, month, year } } = values;
        if (!day.trim() || !month.trim() || !year.trim()) {
            return "Birthdate Required";
        } else {
            const parsedDate = Date.UTC(Number(year), Number(month) - 1, Number(day));
            if (isNaN(parsedDate) || differenceInYears(Date.now(), parsedDate) >= 120) {
                return "Please enter a valid MM/DD/YYYY Birthdate";
            }
        }
    }

    const handleValidateSSN = (ssn = values.ssnTin) => {
        const value = ssn.trim();
        if (!value) {
            return 'SSN Required';
        }
    }

    const allValid = () => {
        return !(handleValidateName(values.clerkName)
            || handleValidateName(values.name)
            || handleValidatePhone()
            || handleValidateAddress()
            || handleValidateCity()
            || handleValidateState()
            || handleValidateZip()
            || handleValidateIdType()
            || handleValidateIdIssuer()
            || handleValidateIdNumber()
            || handleValidateBirthdate()
            || handleValidateSSN());
    }

    const removeLastMoneyOrder = () => {
        setMoneyOrders((currentMoneyOrders) =>
            currentMoneyOrders.slice(0, currentMoneyOrders.length - 1)
        );
    }

    const handleClose = () => {
        if (!allValid()) {
            removeLastMoneyOrder();
        } else {
            onClose();
        }
    }

    const triggerValidation = () => {
        setTouched({
            ...touched,

            clerkName: true,

            name: true,
            phone: true,
            addr1: true,
            city: true,
            state: true,
            zip: true,

            idType: true,
            idIssuer: true,
            idNumber: true,

            birthdate: {
                day: true,
                month: true,
                year: true
            },

            ssnTin: true
        });
    }

    const handleContinue = () => {
        if (allValid()) {
            onClose();
        } else {
            triggerValidation();
        }
    }

    const handleDayChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (Number(value) > 3 || value.length === 2) {
            focusNextElement();
        }
    }

    const handleMonthChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (Number(value) > 1 || value.length === 2) {
            focusNextElement();
        }
    }

    return (
        <BasicPopup
            onClose={handleClose}
            className='InstrumentLogPopup'
            open={open}
            fadeIn>
            <div>
                <h4>Additional information is required for sales of $3,000 or more.</h4>
            </div>
            <MessageAggregator />
            <div className='InstrumentLog' >
                <div className='FormSection'>
                    <fieldset>
                        <AdvancedField type='text'
                            name='name'
                            placeholder='CUSTOMER NAME'
                            maxLength={35}
                            validate={handleValidateName}
                            upperCase={true} />
                        <AdvancedField type='tel'
                            name='phone'
                            placeholder='CUSTOMER PHONE'
                            maxLength={15}
                            validate={handleValidatePhone}
                            upperCase={true}
                            numeric={true} />
                        <AdvancedField type='text'
                            name='addr1'
                            placeholder="CUSTOMER ADDRESS"
                            validate={handleValidateAddress}
                            maxLength={35}
                            upperCase={true} />
                        <AdvancedField type='text'
                            name='city'
                            placeholder="CUSTOMER CITY"
                            maxLength={20}
                            validate={handleValidateCity}
                            upperCase={true} />
                        <div>
                            <div className='State'>
                                <Field
                                    component='select'
                                    name='state'
                                    validate={handleValidateState}
                                >
                                    <option value=''>STATE</option>
                                    {stateOptions}
                                </Field>
                            </div>
                            <div className='Zip'>
                                <AdvancedField type='text'
                                    name='zip'
                                    placeholder="CUSTOMER ZIP"
                                    maxLength={5}
                                    validate={handleValidateZip}
                                    upperCase={true}
                                    numeric={true} />
                            </div>
                        </div>
                    </fieldset >
                    <fieldset>
                        <div>
                            <AdvancedField type='text'
                                name='clerkName'
                                placeholder='CLERK NAME'
                                maxLength={35}
                                validate={handleValidateName}
                                upperCase={true} />
                            <div className='IdType'>
                                <Field
                                    component='select'
                                    name='idType'
                                    validate={handleValidateIdType}
                                >
                                    <option value=''>CUSTOMER ID TYPE</option>
                                    <option value='DL'>DRIVER'S LICENSE</option>
                                    <option value='PASSPORT'>PASSPORT</option>
                                    <option value='ALIENID'>ALIEN ID</option>
                                </Field>
                            </div>
                            <div className='IdIssuer'>
                                <Field
                                    component='select'
                                    name='idIssuer'
                                    validate={handleValidateIdIssuer}
                                >
                                    <option value=''>ID ISSUER</option>
                                    {stateOptions}
                                </Field>
                            </div>
                        </div>

                        <AdvancedField type='text'
                            name='idNumber'
                            placeholder={`${values.idType || 'ID'} NUMBER`}
                            maxLength={20}
                            validate={handleValidateIdNumber}
                            upperCase={true} />
                        <div className='Birthdate'>
                            <div>
                                <div>CUSTOMER</div>
                                <div>BIRTHDATE</div>
                            </div>
                            <AdvancedField
                                name='birthdate.month'
                                placeholder='MM'
                                className='Month'
                                maxLength={2}
                                onChange={handleMonthChange}
                                validate={handleValidateBirthdate} />
                            <AdvancedField
                                name='birthdate.day'
                                placeholder='DD'
                                className='Day'
                                maxLength={2}
                                onChange={handleDayChange}
                                validate={handleValidateBirthdate} />
                            <AdvancedField
                                name='birthdate.year'
                                placeholder='YYYY'
                                className='Year'
                                maxLength={4}
                                validate={handleValidateBirthdate} />
                        </div>
                        <AdvancedField type='text'
                            name='ssnTin'
                            placeholder='SSN / TIN'
                            maxLength={9}
                            validate={handleValidateSSN}
                            upperCase={true}
                            numeric={true} />
                    </fieldset >
                </div>
                <div>
                    <AdvancedButton type='button' onClick={handleContinue}>Continue</AdvancedButton>
                </div>
            </div>
        </BasicPopup>
    );
}